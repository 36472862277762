import React from 'react';
import Resources from '../Resources/Resources';

const providers = (props) => {
    const resource = {
        title: 'Clientes',
        url: '/clients',
        colsHeader: [
            { field: 'name', header: 'Nombre', sortable: true, filter: true, filterMatchMode: 'contains' },
            { field: 'email', header: 'Email', sortable: true, filter: true, filterMatchMode: 'contains' },
            { field: 'phone', header: 'Teléfono', sortable: true, filter: true },
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            show: '/client/',
            edit: '/edit'
        },
        customActions: [
            { field: 'provider_channels', uri: '/channels', icon: 'fa-regular fa-bell', color: 'navy-button'},
            { field: 'clear_unread_messages', uri: '/provider/clear_unread_messages', icon: 'fa-regular fa-envelope-open', color: 'navy-button'},
            { field: 'create_analytic', uri: '/provider/analytic', icon: 'fa-regular fa-chart-pie', color: 'navy-button'}
        ],
        urlTable: [
            '/clients',
            '/clients/new',
            '^\\/client\\/\\d+$',
            '^\\/client\\/\\d+\\/edit$',
            '^\\/client\\/\\d+\\/channels$'
        ]
    }

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                       action={resource.action} customActions={resource.customActions} urlTable={resource.urlTable} />);
}

export default providers;