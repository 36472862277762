import React, {Component, Fragment} from 'react'
import {Link, withRouter} from 'react-router-dom';
import ApiLoan from '../../../service/ApiLoanService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Checkbox} from 'primereact/checkbox';
import CheckboxForm from "../../../UI/CheckboxForm";
import {showErrorMessage, showSuccessMessage, showWarningMessage} from "../../../shared/messagesToast";
import {showToast} from "../../../store/actions";
import connect from "react-redux/es/connect/connect";
import TruncateMarkup from 'react-truncate-markup';
import striptags from 'striptags';
import DropdownFilter from './DropdownFilter';
import MultiSelectFilter from "./MultiSelectFilter";
import SweetAlert from "react-bootstrap-sweetalert";
import MoneyField from '../../../UI/MoneyField';
import ModalContactList from '../../Clients/Contacts/ModalContactList';
import ModalShortUrl from '../../Clients/ShortUrl/ModalShortUrl';
import DateField from '../../../UI/DateField';
import { saveAs } from 'file-saver';
import {TableContext} from "../../../context/tableContext";
import { withLastLocation } from 'react-router-last-location';
import {Chips} from 'primereact/chips';
import '../../../assets/css/DataTableGeneric.css';
import { debounce } from 'lodash';
import axios from 'axios';
import * as roles from "../../../shared/roles";
import ModalQrCode from "../../Clients/QrCode/ModalQrCode";
import {switchDayOfWeek} from "../../../shared/daysWeek";
import {calculateNextExecution} from "../../../shared/utility"
import { InputSwitch } from 'primereact/inputswitch';

class DataTableGeneric extends Component {

    _isMounted = false;

    constructor() {
        super();
        this.state = {
            dataTableSelectValue: null,
            dataTableValue: [],
            loading: true,
            first: 0,
            rows: 10,
            totalRecords: 0,
            page: 0,
            pagination: [{first: 0, page: 0}],
            order_by: [],
            sortField: {},
            filters: [],
            filters_table: null,
            alert: null,
            windowClearUnreadMessages: null,
            windowCreateAnalytic: null,
        };

        this.apiService = new ApiLoan();
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.deleteItemDataTable = this.deleteItemDataTable.bind(this);
        this.updateItemDataTable = this.updateItemDataTable.bind(this);
        this.addFormCheckbox = this.addFormCheckbox.bind(this);
        this.onRowDoubleClick = this.onRowDoubleClick.bind(this);
        this.stopSignal = axios.CancelToken.source();
    }

    static contextType = TableContext;

    componentDidMount() {
        this.fetchResources()
    };

    componentWillUnmount() {
        this.context.checkCurrentTable(this.props.urlTable, this.props.history.location.pathname);
        this._isMounted = false;
    }

    fetchResources(){
        this._isMounted = true;
        let cleaned = false;

        if (this.props.lastLocation !== null){
            if (this.context.tableFields.url !== this.props.lastLocation.pathname && this.context.tableFields.url !== null){
                cleaned = this.context.checkCurrentTable(this.props.urlTable, this.props.lastLocation.pathname);
            }
        }
        if (this.state.loading) {
            let params = {};

            params['url'] = this.props.url;

            if (this.props.params){
                if (this.props.params.filters){
                    params['filters'] = [];
                    for (let indexFilter in this.props.params.filters){
                        if (this.context.tableFields.filters === null){
                            params['filters'].push({name: this.props.params.filters[indexFilter].name,
                                op: this.props.params.filters[indexFilter].op,
                                val: this.props.params.filters[indexFilter].val});
                        } else {
                            let index = this.context.tableFields.filters.findIndex((x) => x.val === this.props.params.filters[indexFilter].val);
                            if (index === -1){
                                if (this.context.tableFields.filters.length > 0){
                                    let paramReset = {};
                                    paramReset["reset"] = true;
                                    this.context.updateTableFields(paramReset);
                                }
                            }
                            params['filters'].push({name: this.props.params.filters[indexFilter].name,
                                op: this.props.params.filters[indexFilter].op,
                                val: this.props.params.filters[indexFilter].val});
                        }
                    }
                }

                if (this.props.params.order_by){
                    params['order_by'] = [];
                    params['order_by'].push({field: this.props.params.order_by.field, direction: this.props.params.order_by.direction});
                }

            }

            if (this.context.tableFields.url === this.props.location.pathname && this.context.tableFields.url !== null && !cleaned){
                if (params['filters'] === undefined && this.context.tableFields.filters !== null){
                    params['filters'] = [];
                }

                if (params['order_by'] === undefined && this.context.tableFields.order_by !== null && this.context.tableFields.order_by !== undefined){
                    params['order_by'] = [];
                }

                for (let order in this.context.tableFields.order_by){
                    params['order_by'].push(this.context.tableFields.order_by[order]);
                }
                for (let filter in this.context.tableFields.filters){
                    params['filters'].push(this.context.tableFields.filters[filter]);
                }
                if (this.context.tableFields.page !== 0) {
                    params['page'] = this.context.tableFields.page;
                }

                let filters = this.setInitFilters(params['filters']);

                this.setState({
                    filters_table: filters,
                    sortField: this.context.tableFields.sort
                });
            }

            this.apiService.getResources(params)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({
                            dataTableValue: response.data.objects.slice(0, this.state.rows),
                            totalRecords: response.data.num_results,
                            loading: false,
                            first: this.context.tableFields.first
                        });
                        params['url'] = this.props.location.pathname;
                        this.context.updateTableFields(params);
                    }

                }).catch(error => {
                    this.setState({loading: false});
                    showErrorMessage(this, "Datos", 'Se produjo un error. Intente nuevamente en unos minutos');
                });
        }
    }

    setInitFilters = (filters_params) => {
        let filters = {};
        let cols = this.props.colsHeader;
        let values = [];
        for (let index in filters_params){
            let name = this.setNameFilter(cols, filters_params[index].name);
            let value = this.setInitFilterValue(filters_params[index]);
            let matchMode = this.setInitFilterMatchMode(filters_params[index]);

            if (filters_params[index].op !== 'any'){
                filters[name] = {
                    value: value,
                    matchMode: matchMode
                }
            } else {
                values.push(value);
                filters[name] = {
                    value: values,
                    matchMode: matchMode
                }
            }
        }
        return filters;
    };

    setNameFilter = (cols, name) => {
        let col = cols.find(x => x.relation === name);
        let field = null;
        if (col === undefined){
            col = cols.find(x => x.field === name);
        }
        if (col !== undefined){
            field = col.field;
        }
        if (this.props.params !== undefined){
            for (let indexFilter in this.props.params.filters){
                if (this.props.params.filters[indexFilter].name === name) {
                    field = name
                }
            }
        }
        return field;
    };

    setInitFilterValue = (filter) => {
        let value = null;
        if (filter.op === 'has'){
            value = filter.val;
        }
        if (filter.op === 'like'){
            value = filter.val.replace(/%/g,'');
        }
        if (filter.op === 'any'){
            value = filter.val;
        }

        return value;
    };

    setInitFilterMatchMode = (filter) => {
        let matchMode = null;
        let cols = this.props.colsHeader;

        let col = cols.find(x => x.relation === filter.name);
        if (col === undefined){
            col = cols.find(x => x.field === filter.name);
        }
        if (col !== undefined){
            if (col.filter){
                if (col.filter_dropdown !== undefined){
                    matchMode = 'equals';
                } else if (col.multiselect_dropdown !== undefined){
                    matchMode = 'equals';
                } else if (col.filterMatchMode !== undefined){
                    matchMode = 'contains';
                } else {
                    matchMode = 'startsWith';
                }
            }
        }
        return matchMode;
    };

    getFiltersDropdown = (contextFilters)  => {
        let cols = this.props.colsHeader;
        let filters = [];

        for (let index in contextFilters){
            let col = cols.find(x => x.relation === contextFilters[index].name);
            if (col === undefined){
                col = cols.find(x => x.field === contextFilters[index].name);
            }
            if (col !== undefined) {
                if (col.filter_dropdown !== undefined){
                    filters.push({
                        name: col.field,
                        value: contextFilters[index].val.replace(/%/g,'')
                    });
                }
            }
        }
        return filters;
    };

    getFiltersMultiselect = (contextFilters) => {
        let cols = this.props.colsHeader;
        let filters = [];

        for (let index in contextFilters){
            let col = cols.find(x => x.relation === contextFilters[index].name);
            if (col !== undefined){
                if (col.multiselect_dropdown !== undefined){
                    filters.push({
                        name: col.field,
                        value: contextFilters[index].val
                    });
                }
            }
        }
        return filters;
    };

    getPayOrderStatusAction = (rowData) => {
        let role = roles.switchRole();
        let field = '';
        switch (role) {
            case 'collector':
                field = rowData['payment_status'];
                break;
            case 'payment_order_creator':
                field = rowData['payment_status']['description'];
                break;
            default:
                break;

        }
        return field;

    }



    actionTemplate = (rowData, column) => {
        let canShow = this.props.action.show !== undefined;
        let showButtonView = this.props.action.canShowButtonView !== undefined ? true : this.props.action.canShowButtonView;
        let canEdit = this.props.action.edit !== undefined;
        let canDuplicate = this.props.action.duplicate !== undefined;
        let show_resource = null;
        let edit_resource = null;
        let duplicate_resource = null;
        let canDelete = this.props.action.delete !== undefined ? this.props.action.delete : false;

        let delete_resource = null;
        let customActions = [];

        if (canShow && showButtonView){
            show_resource = (
                <Link to={this.props.action.show + rowData.id}>
                    <FontAwesomeIcon icon={"search"} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
                </Link>
            );
        }

        if (canEdit){
            edit_resource = (
                <Link to={this.props.action.show + rowData.id + this.props.action.edit }>
                    <FontAwesomeIcon icon={"fa-regular fa-pen"} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"} />
                </Link>
            );
        }

        if (canDuplicate){
            const url = this.props.action.duplicate
            duplicate_resource = (
                <button className="p-link" onClick={(event) => this.duplicateResourse(event, rowData, url )}>
                    <FontAwesomeIcon icon={'fa-regular fa-copy'} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"} />
                </button>
            );
        }

        if (canDelete){
            let isUser = localStorage.getItem('username') === rowData?.username
            delete_resource = (
                <button disabled={isUser} className="p-link" onClick={(event) => {
                    this.confirmDeleteResource(event, rowData)
                    }}>
                    <FontAwesomeIcon icon={"fa-regular fa-trash-can"} color={isUser ? 'grey' :'red'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
                </button>
            );
        }
        for (let index in this.props.customActions){
            if (this.props.customActions[index].field === 'jwt'){
                customActions.push(
                    <Link key={index} to={this.props.customActions[index].uri + rowData.id}>
                        <FontAwesomeIcon icon={"dollar-sign"} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
                    </Link>
                );
            }
            if (this.props.customActions[index].field === 'qr_code'){
                customActions.push(
                    <ModalQrCode client_id={rowData.id} key={index}/>
                );
            }

            if (this.props.customActions[index].field === 'contacts'){
                customActions.push(
                    <ModalContactList client_id={rowData.id} key={index} />
                );
            }
            if (this.props.customActions[index].field === 'short_url'){
                customActions.push(
                    <ModalShortUrl client_id={rowData.id} key={index}/>
                );
            }
            if (this.props.customActions[index].field === 'google_link'){
                customActions.push(
                    <a key={index} target="_blank" rel="noopener noreferrer" href={'https://www.google.com/search?q=%22' + rowData.firstname + ' ' + rowData.lastname  + '%22'}>
                        <FontAwesomeIcon icon={faGoogle} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
                    </a>
                );
            }
            if (this.props.customActions[index].field === 'pay_order_debtor'){
                customActions.push(
                    <Link key={index} to={this.props.customActions[index].uri + rowData.client_id}>
                        <FontAwesomeIcon icon={"search"} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
                    </Link>
                );
            }
            if (this.props.customActions[index].field === 'provider_channels'){
                customActions.push(
                    <Link key={index} to={this.props.action.show + rowData.id + this.props.customActions[index].uri }>
                        <FontAwesomeIcon icon={this.props.customActions[index].icon} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
                    </Link>
                );
            }
            if (this.props.customActions[index].field === 'clear_unread_messages'){
                customActions.push(
                    <div key={index}>
                        <FontAwesomeIcon onClick={(e) => this.confirmClearUnreadMessages(e, this.props.customActions[index].uri, rowData)} icon={this.props.customActions[index].icon} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
                    </div>
                );
            }
            if (this.props.customActions[index].field === 'create_analytic'){
                customActions.push(
                    <div key={index}>
                        <FontAwesomeIcon onClick={(e) => this.confirmCreateAnalytic(e, this.props.customActions[index].uri, rowData)} icon={this.props.customActions[index].icon} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
                    </div>
                );
            }
            if (this.props.customActions[index].field === 'get_csv'){
                let url = this.props.customActions[index].uri + rowData['file_id'];
                let filename = rowData.file.name;

                customActions.push(
                    <button className="p-link" onClick={(event) => this.getBlob(event, url, filename)}>
                        <FontAwesomeIcon icon={"fa-regular fa-download"} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome "}/>
                    </button>
                );
            }
            if (this.props.customActions[index].field === 'revert'){
                let url = this.props.customActions[index].uri;

                customActions.push(
                    <button className="p-link" onClick={(event) => this.confirmRevertResource(event, url, rowData)}>
                        <FontAwesomeIcon icon={"undo"} size={"xs"} color={'red'} className={"btn-actions btn-fontawesome"}/>
                    </button>
                );
            }

            if (this.props.customActions[index].field === 'revert_coelsa'){
                let url = this.props.customActions[index].uri;
                if (rowData.last){
                    customActions.push(
                        <button className="p-link" onClick={(event) => this.confirmRevertResource(event, url, rowData)}>
                            <FontAwesomeIcon icon={"undo"} size={"xs"} color={'red'} className={"btn-actions btn-fontawesome"}/>
                        </button>
                    );
                }
            }

            if (this.props.customActions[index].field === 'cancel_pay_order'){

                let url = this.props.customActions[index].uri;
                let dataForm = {
                    'timestamp_hash': rowData.timestamp_hash
                }

                let cancelParams = {
                    'messageDetail': 'Orden de Pago',
                    'row': {
                        'update': true,
                        'name': 'payment_status',
                        'value': 'Cancelado'
                    }
                }

                let payment_status = this.getPayOrderStatusAction(rowData)

                if (payment_status === 'Nuevo'){

                    customActions.push(
                        <button key={index} className="p-link" onClick={(event) => this.confirmCancelResource(event, url, dataForm, cancelParams)}>
                            <FontAwesomeIcon icon={"fa-regular fa-trash-can"} color={'red'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
                        </button>
                    );
                }
            }

            if (this.props.customActions[index].field === 'transaction_request_actions'){
                let btns = this.getTransactionRequestActions(rowData);
                for (let btn in btns){
                    customActions.push(btns[btn]);
                }
            }

        }

        return (
            <div className="actions">
                {show_resource}
                {edit_resource}
                {duplicate_resource}
                {delete_resource}
                {customActions}
            </div>
        );
    };

    getTransactionRequestActions = (rowData) => {
        let cancel_button = false;
        let approved_button = false;
        let buttons = [];
        let role = roles.switchRole();

        switch (rowData.transaction_status.slug) {
            case 'new':
                cancel_button = true;
                break;
            case 'done':
                break;
            case 'rejected':
                break;
            case 'expired':
                break;
            case 'pending':
                if (role === 'manager'){
                    cancel_button = true;
                    approved_button = true;
                }
                break;
            default:
                break;
        }

        if (cancel_button){
            buttons.push(this.buttonRejected(rowData.id));
        }
        if (approved_button){
            buttons.push(this.buttonApproved(rowData.id));
        }

        return buttons;

    };

    buttonRejected = (id) => {
        let operation = 'cancel';
        return (
            <button key={'cancel'+id} className="p-link" onClick={(event) => this.confirmResource(event, operation, id)}>
                <FontAwesomeIcon icon={"thumbs-down"} size={"xs"} style={{background: 'red'}} color={'white'} className={"btn-actions btn-fontawesome"}/>
            </button>
        );
    }

    buttonApproved = (id) => {
        let operation = 'approve';
        return (
            <button key={'approve'+id} className="p-link" onClick={(event) => this.confirmResource(event, operation, id)}>
                <FontAwesomeIcon icon={"thumbs-up"} size={"xs"} style={{background: 'green'}} color={'white'} className={"btn-actions btn-fontawesome"}/>
            </button>
        );
    }

    getBlob = (event, url, filename) => {
        this.apiService.getResourceBlob({
            url: url
        }).then(response => {
            saveAs(response.data, filename);
        }).catch(error => {
            showErrorMessage(this, "Descarga", 'No se pudo descargar el archivo.');
        });
    };

    confirmDeleteResource = (event, row) => {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    showCancel
                    cancelBtnText={<i className="pi pi-times">&nbsp;Cancelar</i>}
                    confirmBtnText={<i className="pi pi-check">&nbsp;Eliminar</i>}
                    title="¿Está seguro?"
                    onConfirm={e => this.deleteResource(e, row)}
                    onCancel={(event) => this.hideAlert(event)}
                    confirmBtnCssClass={
                        "p-button p-component p-button-success p-button-rounded"
                    }
                    cancelBtnCssClass={
                        "p-button p-component p-button-danger p-button-rounded"
                    }
                >
                    No va a poder revertir esta acción!
                </SweetAlert>
            )
        });
    };

    confirmRevertResource = (event, url, row) => {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    showCancel
                    cancelBtnText={<i className="pi pi-times">&nbsp;Cancelar</i>}
                    confirmBtnText={<i className="pi pi-check">&nbsp;Eliminar</i>}
                    title="¿Está seguro?"
                    onConfirm={e => this.revertResource(e, url, row)}
                    onCancel={(event) => this.hideAlert(event)}
                    confirmBtnCssClass={
                        "p-button p-component p-button-success p-button-rounded"
                    }
                    cancelBtnCssClass={
                        "p-button p-component p-button-danger p-button-rounded"
                    }
                >
                    No va a poder revertir esta acción!
                </SweetAlert>
            )
        });
    };

    confirmResource = (event, operation, row_id) => {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    showCancel
                    cancelBtnText={<i className="pi pi-times">&nbsp;Cancelar</i>}
                    confirmBtnText={<i className="pi pi-check">&nbsp;Eliminar</i>}
                    title="¿Está seguro?"
                    onConfirm={e => this.transactionResource(e, operation, row_id)}
                    onCancel={(event) => this.hideAlert(event)}
                    confirmBtnCssClass={
                        "p-button p-component p-button-success p-button-rounded"
                    }
                    cancelBtnCssClass={
                        "p-button p-component p-button-danger p-button-rounded"
                    }
                >
                    No va a poder revertir esta acción!
                </SweetAlert>
            )
        });
    };

    confirmCancelResource = (event, url, dataForm, cancelParams) => {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    showCancel
                    cancelBtnText={<i className="pi pi-times">&nbsp;Cancelar</i>}
                    confirmBtnText={<i className="pi pi-check">&nbsp;Eliminar</i>}
                    title="¿Está seguro?"
                    onConfirm={e => this.cancelResource(e, url, dataForm, cancelParams)}
                    onCancel={(event) => this.hideAlert(event)}
                    confirmBtnCssClass={
                        "p-button p-component p-button-success p-button-rounded"
                    }
                    cancelBtnCssClass={
                        "p-button p-component p-button-danger p-button-rounded"
                    }
                >
                    No va a poder revertir esta acción!
                </SweetAlert>
            )
        });
    }

    duplicateResourse = (event, rowData, url) =>{
        this.setState({loading: true})
        let apiService = new ApiLoan();
        apiService.postResource({
            url: url,
            data: {'resource_id': rowData.id}
        }).then(response => {
            showSuccessMessage(this, "Formulario duplicado", "Se duplicó el bot correctamente");
            setTimeout(() => {
                this.fetchResources()
            }, 1000);
        }).catch(error => {
        // Check if there is a response and a message body
        let messageDetail = 'Error al querer duplicar el recurso';
        if (error.response && error.response.data) {
            const errors = error.response.data;
            const errorMessages = [];
            // Iterate over the keys of the error object and add their messages
            for (const key in errors) {
                if (errors.hasOwnProperty(key)) {
                    const errorMessage = errors[key];
                    if (Array.isArray(errorMessage)) {
                        // Concatenate all error messages for this key
                        errorMessages.push(...errorMessage);
                    } else {
                        // Add the error message if it's not an array
                        errorMessages.push(errorMessage);
                    }
                }
            }
            // Join all error messages into a single string
            if (errorMessages.length > 0) {
                messageDetail = errorMessages.join(' ');
            }
        }   
            this.setState({loading: false})
            showErrorMessage(this, "Duplicar", messageDetail);
        });
     }

    deleteResource = (event, row) => {
        this.apiService.deleteResource({
            url: this.props.url,
            resource_id: row.id
        }).then(response => {
            this.setState({
                alert: null
            });

            this.deleteItemDataTable(row)
            showSuccessMessage(this, "Eliminar", "Se elimino satisfactoriamente");
        }).catch(error => {
            this.setState({
                alert: null
            });
            let messageDetail = error.response.data?.message !== null ?
                error.response.data.message : 'Error al querer eliminar el recurso';
            showErrorMessage(this, "Eliminar", messageDetail);
        });

    };

    enabledResource = (event, row) => {
        this.apiService.getResource({
                url: this.props.url+"/",
                resource_id: row.id
        }).then(res=>{
            res.data.enabled?res.data.enabled=false:res.data.enabled=true;
            let formData = {};
            let data = {
                channel_id : res.data.channel_id,
                default_status_id : res.data.default_status_id,
                enabled: res.data.enabled,
                fpd: res.data.fpd,
                frecuency: res.data.frecuency,
                interoperation: res.data.interoperation,
                notification_custom_tags: res.data.notification_custom_tags,
                notification_type: res.data.notification_type,
                provider_id: res.data.provider_id,
                title: res.data.title
            };
            formData = {
                url: this.props.url+"/",
                resource_id: row.id,
                data: data
                };
            this.apiService.patchResource(formData).then(()=>{
            if (data.enabled) {
                showSuccessMessage(this, "Habilitar", "Se habilito satisfactoriamente");
            }else{
                showSuccessMessage(this, "Deshabilitar", "Se deshabilito satisfactoriamente");
            }
            this.updateItemDataTable(row);
        }).catch(()=>{
            this.setState({
                   alert: null
               });
            showErrorMessage(this, "Error al querer habilitar/deshabilitar el recurso");
        });
        }).catch(()=>{
            this.setState({
                   alert: null
               });
            showErrorMessage(this, "Error al querer habilitar/deshabilitar el recurso");
        });
    };

    onClearUnreadMessages = (event, url, row) => {
        event .preventDefault();
        const formData = {};
        formData['id'] = row.id;
        this.apiService.postResource({
            url: url,
            data: formData
        }).then(response => {
            this.setState({
                alert: null
            });
            if (response.status === 200){
                showSuccessMessage(this, "Limpiar Mensajes no leídos", "Se limpio correctamente");
            } else {
                showErrorMessage(this, "Limpiar Mensajes no leídos", "Error en limpiar los mensajes no leídos");
            }
        }).catch(() => {
            this.setState({
                alert: null
            });
            showErrorMessage(this, "Limpiar Mensajes no leídos", "Error en limpiar los mensajes no leídos");
        }).finally(() => this.setState({windowClearUnreadMessages: null}));
    }

    confirmClearUnreadMessages = (event, url, row) => {
        this.setState({
          windowClearUnreadMessages: (
            <Fragment>
              <SweetAlert
                info
                showCancel
                cancelBtnText={<i class="pi pi-times">&nbsp;No</i>}
                confirmBtnText={<i class="pi pi-check">&nbsp;Si</i>}
                title="¡Está seguro!"
                onConfirm={() => this.onClearUnreadMessages(event, url, row)}
                onCancel={() => this.setState({ windowClearUnreadMessages: null })}
                confirmBtnCssClass="p-button p-component p-button-success p-button-rounded"
                cancelBtnCssClass="p-button p-component p-button-danger p-button-rounded"
              >
                ¿Que quieres limpiar los mensajes no leídos?
              </SweetAlert>
            </Fragment>
          ),
        });
      };

    onCreateAnalytic = (event, url, row) => {
        event .preventDefault();
        const formData = {};
        let detailError = "Error en crear analítica";
        let detailSuccess = "Se creo correctamente";
        let title = "Crear Analítica"
        formData['id'] = row.id;
        formData['slug'] = row.slug;
        formData['name'] = row.name;
        this.apiService.postResource({
            url: url,
            data: formData
        }).then(response => {
            this.setState({
                alert: null
            });
            if (response.data?.message !== undefined) {
                detailSuccess = response.data?.message
            }
            if (response.status === 200){
                showSuccessMessage(this, title, detailSuccess);
            } else {
                showErrorMessage(this, title, detailSuccess);
            }
        }).catch((error) => {
            this.setState({
                alert: null
            });
            if (error.response.data?.message !== undefined) {
                detailError = error.response.data?.message
            }
            showErrorMessage(this, title, detailError);
        }).finally(() => this.setState({windowCreateAnalytic: null}));
    }

    confirmCreateAnalytic = (event, url, row) => {
        this.setState({
            windowCreateAnalytic: (
                <Fragment>
                    <SweetAlert
                        info
                        showCancel
                        cancelBtnText={<i class="pi pi-times">&nbsp;No</i>}
                        confirmBtnText={<i class="pi pi-check">&nbsp;Si</i>}
                        title="¡Está seguro!"
                        onConfirm={() => this.onCreateAnalytic(event, url, row)}
                        onCancel={() => this.setState({ windowCreateAnalytic: null })}
                        confirmBtnCssClass="p-button p-component p-button-success p-button-rounded"
                        cancelBtnCssClass="p-button p-component p-button-danger p-button-rounded"
                    >
                        ¿Que quieres crear la analítica?
                        Tiempo de espera 5 min aprox
                    </SweetAlert>
                </Fragment>
            ),
        });
    };


    revertResource = (event, url, row) => {
        const formData = {};
        formData['id'] = row.id;
        if (url === '/coelsa/revert'){
            formData['description'] = row.description;
        }
        this.apiService.postResource({
            url: url,
            data: formData
        }).then(response => {
            this.setState({
                alert: null
            });
            if (response.status === 201){
                showSuccessMessage(this, "Log", response.data.message);
                if (url === '/coelsa/revert'){
                    this.props.history.replace({ pathname: this.props.location.pathname, state: {update: true}});
                }
            } else if (response.status === 200){
                showWarningMessage(this, "Log", response.data.message);
            } else {
                showErrorMessage(this, "Log", response.data.message);
            }
        }).catch(error => {
            this.setState({
                alert: null
            });
            let msg = error.response.data.message !== undefined ? error.response.data.message :
                    'Error al querer revertir la operación';
            showErrorMessage(this, "Log", msg);
        });
    };

    transactionResource = (event, operation, row_id) => {
        const formData = {};
        let url = '/coelsa/transaction_request_change';
        formData['id'] = row_id;
        formData['operation'] = operation;
        this.apiService.postResource({
            url: url,
            data: formData
        }).then(response => {
            if (response.status === 201){
                showSuccessMessage(this, "Liquidacion", 'Se modifico satisfactoriamente');

                let itemsList = [...this.state.dataTableValue];

                itemsList.map(x => {
                    if (x.id === row_id){
                        x['transaction_status'] = response.data;
                    }
                    return x;
                });

                this.setState({
                    dataTableValue: itemsList,
                    alert: null
                });
            }

        }).catch(error => {
            this.setState({
                alert: null
            });

            showErrorMessage(this, "Liquidacion", 'No se ha podido modificar la transacción');
        });
    };

    cancelResource = (event, url, dataForm, cancelParams) => {
        this.apiService.postResource({
            url: url,
            data: dataForm
        }).then(response => {
            this.setState({
                alert: null
            });
            if (response.status === 201){
                showSuccessMessage(this, cancelParams.messageDetail, response.data.message);
                if (cancelParams.row.update){
                    this.updateRow(dataForm, 0, cancelParams.row)
                }
            } else if (response.status === 200){
                showWarningMessage(this, cancelParams.messageDetail, response.data.message);
            } else {
                showErrorMessage(this, cancelParams.messageDetail, response.data.message);

            }
        }).catch(error => {
            this.setState({
                alert: null
            });
            let msg = error.response.data.message !== undefined ? error.response.data.message :
                'Error al querer revertir la operación';
            showErrorMessage(this, "Log", msg);
        });
    };

    updateRow = (item, position, row) => {
        let itemsList = [...this.state.dataTableValue];

        let key = Object.keys(item)[position];

        let items = itemsList.map(x => {
            if (x[key] === item[key]){
                let itemsListFiltered = itemsList.filter((c) => c[key] === item[key]);
                if (itemsListFiltered.length > 0){
                    let rowChanged = itemsListFiltered[0];
                    rowChanged[row.name] = row.value;
                    return rowChanged
                }
            }
            return x;
        });

        this.setState({
            dataTableValue: items
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    onPage(event) {
        let pagination = this.state.pagination.slice(0);
        let componentPagination = pagination.pop();
        let page = (event.hasOwnProperty('page') || componentPagination['page']) && event.page;
        let params = {};

        const startIndex = event.first;

        this.setState({
            loading: true
        });

        params['page'] = page + 1;

        if (this.state.order_by){
            params['order_by'] = this.state.order_by;
        }

        if (params['filters'] === undefined && this.context.tableFields.filters !== null){
            params['filters'] = [];
        }

        for (let filter in this.context.tableFields.filters){
            params['filters'].push(this.context.tableFields.filters[filter]);
        }

        let filters = this.setInitFilters(params['filters']);

        if (this.props.params){
            if (this.props.params.filters){
                if (params['filters'] === undefined){
                    params['filters'] = [];
                }

                for (let indexFilter in this.props.params.filters){
                    if (this.context.tableFields.filters === null){
                        params['filters'].push({name: this.props.params.filters[indexFilter].name,
                            op: this.props.params.filters[indexFilter].op,
                            val: this.props.params.filters[indexFilter].val});
                    } else {
                        let index = this.context.tableFields.filters.findIndex((x) => x.name === this.props.params.filters[indexFilter].name);
                        if (index === -1){
                            params['filters'].push({name: this.props.params.filters[indexFilter].name,
                                op: this.props.params.filters[indexFilter].op,
                                val: this.props.params.filters[indexFilter].val});
                        }
                    }
                }
            }
        }
        if (this.context.tableFields.order_by){
            params['order_by'] = this.context.tableFields.order_by;
        }
        params['url'] = this.props.url;

        this.apiService.getResources(params)
            .then(response => {
                let pagination = this.state.pagination.slice(0,-1);
                pagination.pop();
                pagination.push({page: params['page'], first: startIndex});

                this.setState({
                    dataTableValue: response.data.objects.slice(0, this.state.rows),
                    totalRecords: response.data.num_results,
                    first: startIndex,
                    pagination: pagination,
                    filters_table: filters,
                    loading: false
                });

                params['url'] = this.props.location.pathname;
                params['first'] = pagination[0].first;
                this.context.updateTableFields(params);
            }).catch(error => {
                this.setState({loading: false});
                showErrorMessage(this, "Página " + this.state.page, 'Se produjo un error. Intente nuevamente en unos minutos');
            });
    }

    onSort(event) {
        let params = {};
        let field = '';
        params['order_by'] = [...this.state.order_by];

        if(event.sortField.includes('.')){
            let col = this.props.colsHeader.find(x => x.field === event.sortField);
            field = col.relation
        } else {
            field = event.sortField;
        }

        if(params['order_by'].length > 0){
            if(params['order_by'][0].field === field) {
                params['order_by'][0]['direction'] = params['order_by'][0]['direction'] === 'desc' ? 'asc' : 'desc'
            } else{
                params['order_by'] = [({field: field, direction: 'asc'})]
            }
        } else {
            params['order_by'] = [({field: field, direction: 'asc'})]
        }

        this.setState({
            loading: true,
            order_by: params['order_by'],
            sortField: {sortField: event.sortField, sortOrder: params['order_by'][0].direction !== 'asc' ? -1 : 1}
        });

        let pagination = this.state.pagination.slice(0);
        let currentPagination = pagination.pop();

        if (currentPagination['page']){
            params['page'] = currentPagination['page'];
            params['first'] = currentPagination['first'];
        }

        if (params['filters'] === undefined && this.context.tableFields.filters !== null){
            params['filters'] = [];
        }

        for (let filter in this.context.tableFields.filters){
            params['filters'].push(this.context.tableFields.filters[filter]);
        }

        let filters = this.setInitFilters(params['filters']);

        if (this.props.params){
            if (this.props.params.filters){
                if (params['filters'] === undefined){
                    params['filters'] = [];
                }
                for (let indexFilter in this.props.params.filters){
                    if (this.context.tableFields.filters === null){
                        params['filters'].push({name: this.props.params.filters[indexFilter].name,
                            op: this.props.params.filters[indexFilter].op,
                            val: this.props.params.filters[indexFilter].val});
                    } else {
                        let index = this.context.tableFields.filters.findIndex((x) => x.name === this.props.params.filters[indexFilter].name);
                        if (index === -1){
                            params['filters'].push({name: this.props.params.filters[indexFilter].name,
                                op: this.props.params.filters[indexFilter].op,
                                val: this.props.params.filters[indexFilter].val});
                        }
                    }
                }
            }
        }

        params['url'] = this.props.url;

        this.apiService.getResources(params)
            .then(response => {
                this.setState({
                    dataTableValue: response.data.objects.slice(0, this.state.rows),
                    totalRecords: response.data.num_results,
                    filters_table: filters,
                    loading: false
                });
                params['url'] = this.props.location.pathname;
                params['sort'] = {
                    sortField: event.sortField,
                    sortOrder: event.sortOrder
                };
                this.context.updateTableFields(params);
            }).catch(error => {
                this.setState({loading: false});
                showErrorMessage(this, "Ordenar", 'Se produjo un error. Intente nuevamente en unos minutos');
            });
    }

    onFilter(event) {
        let params = {};
        params['filters'] = [];

        let filtersState = [
            ...this.state.filters
        ];

        let filtersStateDeleted = [];
        filtersState.map(filter => {
            let filter_exist = Object.keys(event.filters).filter((x) => x === filter.name);
            if (filter_exist.length === 0) {
                let filter_index = filtersState.findIndex((x) => x.name === filter.name);
                filtersStateDeleted.push(filter_index);
            }
        });

        filtersStateDeleted.map(filter_delete => {
            filtersState.splice(filter_delete, 1);
        });

        if (this.props.params){
            if (this.props.params.filters){
                for (let indexFilter in this.props.params.filters){
                    if (this.context.tableFields.filters === null){
                        params['filters'].push({name: this.props.params.filters[indexFilter].name,
                            op: this.props.params.filters[indexFilter].op,
                            val: this.props.params.filters[indexFilter].val});
                    } else {
                        let index = params['filters'].findIndex((x) => x.name === this.props.params.filters[indexFilter].name);
                        if (index === -1){
                            params['filters'].push({name: this.props.params.filters[indexFilter].name,
                                op: this.props.params.filters[indexFilter].op,
                                val: this.props.params.filters[indexFilter].val});
                        }
                    }
                }
            }
        }

        let cols = this.props.colsHeader;
        for (let filter in event.filters) {
            let relation = false;
            let relation_multiselect = false;
            let parse_filter = filter;

            let col = cols.find(x => x.field === filter);
            if (col === undefined){
                col = cols.find(x => x.relation === filter);
            }

            if (col !== undefined){
               if (col.filter_dropdown !== undefined){
                   relation = true;
                   parse_filter = col.relation;
               }
               if (col.multiselect_dropdown !== undefined){
                   relation_multiselect = true;
                   parse_filter = col.relation;
               }
            }

            let index = filtersState.findIndex((x) => x.name === parse_filter);

            if (index === -1){
                if (relation){
                    let has_relation = col.filter_dropdown.hasOwnProperty('is_relation');
                    if (has_relation){
                        if (!col.filter_dropdown.is_relation){
                            filtersState.push({name: col.relation, op:'like', val: '%' + event.filters[filter]['value'] + '%'});
                        }
                    } else {
                        filtersState.push({name: col.relation, op:'has', val: event.filters[filter]['value']});
                    }
                } else if (relation_multiselect){
                    if (Array.isArray(event.filters[filter]['value'])){
                        for (let index_filter in event.filters[filter]['value']){
                            filtersState.push({name: col.relation, op:'any', val: event.filters[filter]['value'][index_filter]});
                        }
                    }
                } else {
                    if (this.props.params !== undefined){
                        if (this.props.params.filters){
                            for (let indexFilter in this.props.params.filters){
                                if (filter === this.props.params.filters[indexFilter].name){
                                    if (this.context.tableFields.filters === null){
                                        filtersState.push({name: this.props.params.filters[indexFilter].name,
                                            op: this.props.params.filters[indexFilter].op,
                                            val: this.props.params.filters[indexFilter].val});
                                    } else {
                                        let paramFiltersIndex = params['filters'].findIndex((x) => x.name === this.props.params.filters[indexFilter].name);
                                        if (paramFiltersIndex === -1){
                                            filtersState.push({name: filter,
                                                op: this.props.params.filters[indexFilter].op,
                                                val: this.props.params.filters[indexFilter].val});
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        if (col.relation_object !== undefined){
                            let field_object = {name: col.relation_object, op:'like', val:'%' + event.filters[filter]['value'] + '%'};
                            filtersState.push({name: col.parent_field, op:'has', val: field_object});
                        } else {
                            filtersState.push({name: filter, op:'like', val:'%' + event.filters[filter]['value'] + '%'});
                        }
                    }
                }
            } else {
                if (filtersState[index]['op'] === 'like'){
                    filtersState[index]['val'] = '%' + event.filters[filter]['value'] + '%';
                } else if (filtersState[index]['op'] === 'any'){
                    if (Array.isArray(event.filters[filter]['value'])){
                        for (let index_filter in event.filters[filter]['value']){
                            let indexState = filtersState.findIndex((x) => x.val === event.filters[filter]['value'][index_filter]);
                            if (indexState === -1){
                                filtersState.push({name: col.relation, op:'any', val: event.filters[filter]['value'][index_filter]});
                            } else {
                                filtersState[indexState]['val'] = event.filters[filter]['value'][index_filter];
                            }
                        }
                    }
                } else {
                    if (this.props.params !== undefined){
                        if (this.props.params.filters){
                            for (let indexFilter in this.props.params.filters){
                                if (filter !== this.props.params.filters[indexFilter].name){
                                    filtersState[index]['val'] = event.filters[filter]['value'];
                                }
                            }
                        }
                    } else {
                        filtersState[index]['val'] = event.filters[filter]['value'];
                    }
                }
            }
        }

        let fields_filter = filtersState.filter(field => {
            let parse_field = field['name'];
            let col = cols.find(x => x.field === parse_field);

            if (col === undefined){
                col = cols.find(x => x.relation === parse_field);
            }
            if (col === undefined){
                col = cols.find(x => x.parent_field === parse_field);
            }

            if (col !== undefined){
                if (col.filter_dropdown !== undefined){
                    parse_field = col.field;
                }
                if (col.relation_object !== undefined){
                    parse_field = col.field;
                }

            }
            if (event.filters.length > 0){
                return Object.keys(event.filters).includes(parse_field);
            }

            if (col.multiselect_dropdown !== undefined){
                let field_exist = false;
                if (event.filters[field.name] !== undefined){
                    field_exist = event.filters[field.name].value.includes(field.val);
                }
                return field_exist;
            }
            return true;
        });

        fields_filter = fields_filter.filter(x => x.name !== 'action');
        fields_filter = fields_filter.filter(x => x.val !== '%null%');
        fields_filter = fields_filter.filter(x => x.val !== '%undefined%');

        fields_filter.map(filter => {
            params['filters'].push(filter);
            return filter;
        });

        let pagination = this.state.pagination.slice(0);

        if(Object.keys(event.filters).length > 0 && pagination.length < 2){
            pagination.push({page: 0, first: 0})
        }

        if(Object.keys(event.filters).length === 0){
            pagination.pop();
        }
        this.setState({
            pagination: pagination,
            filters: fields_filter,
            filters_table: event.filters
        });

        let cPagination = [...pagination];
        let currentPagination = cPagination.pop();

        if (currentPagination['page']){
            params['page'] = currentPagination['page'];
            params['first'] = currentPagination['first'];
        }

        if (this.state.order_by){
            params['order_by'] = this.state.order_by
        }

        params['url'] = this.props.url;

        this.stopSignal.cancel();

        this.stopSignal = axios.CancelToken.source();
        params['cancelToken'] = this.stopSignal.token;

        let fetchData = debounce(() => {
            this.apiService.getResources(params)
                .then(response => {
                    this.setState({
                        dataTableValue: response.data.objects.slice(0, this.state.rows),
                        totalRecords: response.data.num_results,
                        loading: false,
                        page: currentPagination['page'],
                        first: currentPagination['first']
                    });
                    params['url'] = this.props.location.pathname;
                    params['page'] = currentPagination['page'];

                    this.context.updateTableFields(params);
                }).catch(error => {
                    if (!axios.isCancel(error)) {
                        this.setState({loading: false});
                        showErrorMessage(this, "Buscar", 'Se produjo un error. Intente nuevamente en unos minutos');
                    }
                });
        }, 500);

        fetchData();

    }

    ckeckenabled = (rowData, column) => {
        return <InputSwitch checked={rowData[column.field]} onChange={(e) => this.enabledResource(e, rowData)} />
    };

    addCheckbox = (rowData, column) => {
        return <Checkbox checked={rowData[column.field]} onChange={null} />;
    };

    daysWeek = (rowData, column) => {
        return switchDayOfWeek(rowData[column.field])
    }

    cron = (rowData, column) => {
        return calculateNextExecution(rowData[column.field])
    }

    truncateField = (rowData, column) => {
        let html = striptags(rowData[column.field], [], '\n');
        return <TruncateMarkup lines={1}><div>{html}</div></TruncateMarkup>;
    };

    truncateWord = (rowData, column) => {
        return <div className="truncate-word"> {rowData[column.field]} </div>;
    };

    moneyField = (rowData, column) => {
        return <MoneyField>{rowData[column.field]}</MoneyField>
    };

    childrenField = (rowData, column) => {
        const children = rowData[column.field].map((child) => {
        const childValues =  (<Fragment><p><b>{child['company']}</b></p><p>{ `Registros: ${child['regs']}`}</p><p>{`Total: $${child['amount']}`}</p></Fragment>);
            return childValues;
        })
        return (<Fragment>{children}</Fragment>);
    };

    dateField = (rowData, column) => {
      return <DateField>{rowData[column.field]}</DateField>
    };

    dateTimeField = (rowData, column) => {
        return <DateField format={'DD/MM/YYYY HH:mm:ss'}>{rowData[column.field]}</DateField>
    };

    multiSelectTemplate = (rowData, column) => {
        let header_field = column.field;
        let values = [];

        if (header_field.includes('__')){
            header_field = header_field.split('__');
            header_field = header_field[0];
        }

        let fields = rowData[header_field];

        if (Array.isArray(fields)){
            fields.map(field => {
                if (field.hasOwnProperty('active')){
                    if (field.active){
                        values.push(field.name);
                    }
                }
                return field;
            });

            return <Chips value={values} disabled={true} className="show-chips"/>;
        }
    }

    checkboxChips = (rowData, column) => {
        let value = rowData[column.field];
        let classNameCheckboxChips = null;
        if (column.className !== null){
            classNameCheckboxChips = column?.props?.filterElement?.className
        }
        return <span className={classNameCheckboxChips}>{column?.column?.props?.filterElement?.label[value]}</span>;
    }

    addFormCheckbox = (rowData, column) => {
        return (<CheckboxForm url={this.props.url} resource_id={rowData['id']} checked={rowData[column.field]}/>);
    };

    onRowDoubleClick(e){
        this.props.history.push(this.props.action.show + e.data.id);
    };

    deleteItemDataTable = (item) => {
        let itemsList = [...this.state.dataTableValue];
        let totalRecords = this.state.totalRecords;

        itemsList = itemsList.filter((c) => c.id !== item.id);

        this.setState({
            dataTableValue: itemsList,
            totalRecords: totalRecords - 1
        });
    };

    updateItemDataTable = (item) => {
        let itemsList = [...this.state.dataTableValue];
        itemsList.forEach(element => {
            if(element.id===item.id){
                element.enabled = !element.enabled;
            }
        });
        this.setState({
            dataTableValue: itemsList
        });
    };

    render(){
        let pagination = this.state.pagination.slice(0);
        pagination.pop();
        let footer = "Cantidad de registros: " + this.state.totalRecords;

        let cols = this.props.colsHeader;
        let context_filters = this.context.tableFields.filters;
        let filters = this.state.filters_table;

        if (context_filters === null){
            filters = null;
        }

        let dropdown_filters = [];
        let multiselect_dropdown_filters = [];
        if (context_filters !== null){
            dropdown_filters = this.getFiltersDropdown(context_filters);
            multiselect_dropdown_filters = this.getFiltersMultiselect(context_filters);
        }

        let dynamicColumns = cols.map((col,i) => {
            if (col.field === 'action'){
               return <Column body={this.actionTemplate} field={col.field} header={col.header} key={col.field} style={{textAlign:'center'}}/>;
            } else if (col.checkbox) {
                return <Column body={col.checkbox['updateable'] ? this.addFormCheckbox : this.addCheckbox}
                               key={col.field} field={col.field} header={col.header}
                               sortable={col.sortable} filter={col.filter} filterMatchMode={col.filterMatchMode}/>;
            }else if (col.enabled) {
                return <Column body={this.ckeckenabled}
                               key={col.field} field={col.field} header={col.header}
                               sortable={col.sortable} filter={col.filter} filterMatchMode={col.filterMatchMode}/>;
            } else if (col.daysWeek) {
                return <Column body={this.daysWeek}
                               key={col.field} field={col.field} header={col.header}
                               sortable={col.sortable} filter={col.filter} filterMatchMode={col.filterMatchMode}/>;
            }
            else if (col.cron) {
                return <Column body={this.cron}
                               key={col.field} field={col.field} header={col.header}
                               sortable={col.sortable} filter={col.filter} filterMatchMode={col.filterMatchMode}/>;
            } else if (col.truncate) {
                return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable}
                               filter={col.filter}
                               body={this.truncateField} filterMatchMode={col.filterMatchMode}/>;
            } else if (col.truncate_word) {
                return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable}
                               filter={col.filter}
                               body={this.truncateWord} filterMatchMode={col.filterMatchMode}/>;
            } else if (col.format === 'money'){
                return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable}
                               filter={col.filter} filterPlaceholder={"Buscar"}
                               body={this.moneyField} filterMatchMode={col.filterMatchMode}/>;
            } else if (col.format === 'children'){
                return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable}
                                filter={col.filter} filterPlaceholder={"Buscar"}
                                body={this.childrenField} filterMatchMode={col.filterMatchMode}/>;
            } else if (col.filter_dropdown) {
                let value = null;
                if (dropdown_filters.length > 0){
                    let dropdown_field = dropdown_filters.find(x => x.name === col.field);
                    if (dropdown_field){
                        value = dropdown_field.value
                    }
                }
                return <Column key={col.field} header={col.header} filter={col.filter} field={col.field}
                               filterElement={<DropdownFilter filter={col.filter_dropdown} field={col.field}
                                                              dt={this.dt} value={value} />}/>;
            } else if (col.multiselect_dropdown){
                let values = [];
                if (multiselect_dropdown_filters.length > 0){
                    multiselect_dropdown_filters.map(filter => {
                        let multiselect_field = multiselect_dropdown_filters.find(x => x.value === filter.value);
                        if (multiselect_field){
                            values.push(multiselect_field.value);
                        }
                    });
                }
                return <Column key={col.field} header={col.header} filter={col.filter} field={col.field}
                               body={this.multiSelectTemplate} style={{width:'15%'}}
                               filterElement={
                                <MultiSelectFilter filter={col.multiselect_dropdown} field={col.field} dt={this.dt}
                                                   selectedItemsLabel={col.multiselect_dropdown.selectedItemsLabel}
                                                   value={values} />
                               }/>;

            } else if (col.date) {
                return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable}
                               filter={col.filter} body={this.dateField}/>;
            } else if (col.datetime) {
                return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable}
                               filter={col.filter} body={this.dateTimeField}/>;
            } else if (col.checkbox_chips) {
                return <Column body={this.checkboxChips}
                               key={col.field} field={col.field} header={col.header}
                               filterElement={col.checkbox_chips}/>;
            } else {
                return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable}
                               filter={col.filter} filterMatchMode={col.filterMatchMode}
                               filterPlaceholder={"Buscar"} />;
            }
        });

        return (
            <Fragment>
                {this.state.windowClearUnreadMessages}
                {this.state.windowCreateAnalytic}
                {this.state.alert}
                <div className="datatable-filter-mobile">
                    <div className="card">
                        <DataTable filterDelay="100" filterDisplay="row" className="p-datatable-customers" value={this.state.dataTableValue} header={this.props.header}
                           selection={this.state.dataTableSelectValue}
                           onSelectionChange={e => this.setState({dataTableSelectValue:e.value})}
                           paginator={true} first={this.state.first} totalRecords={this.state.totalRecords}
                           rows={this.state.rows} onPage={this.onPage} lazy={true} loading={this.state.loading}
                           sortOrder = {this.state.sortField.sortOrder} sortField={this.state.sortField.sortField}
                           onSort={this.onSort} onFilter={this.onFilter} footer={footer}
                           filters={filters}
                           emptyMessage="No se encontró ningún resultado"
                           ref={(el) => this.dt = el}>

                            {dynamicColumns}

                    </DataTable>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    showToast
};

export default withRouter(withLastLocation(connect(null, mapDispatchToProps)(DataTableGeneric)));